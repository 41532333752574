@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap');



:root {

  // --theme-color-1:#011F26;
  // --theme-color-2:#025E73;
  // --theme-color-3:#A5A692;
  // --theme-color-4:#F2A71B;
  // --theme-color-5:#BFB78F;

  // --theme-color-1:#1C4859;
  // --theme-color-2:#266573;
  // --theme-color-3:#A7D0D9;
  // --theme-color-4:#3F93A6;
  // --theme-color-5:#F2F2F2;

  // --theme-color-1:#2A465C;
  // --theme-color-2:#326989;
  // --theme-color-3:#f1f0e8;
  // --theme-color-4:#F5AC2D;
  // --theme-color-5:#83BDDA;

  --theme-color-1: white;
  // --theme-color-2: #419d78;
  // --theme-color-2: #3c896d;
  // --theme-color-2: #32de8a;
  // --theme-color-2: #446df6;
  // --theme-color-2: #187795;
  // --theme-color-2: #1c77c3;
  --theme-color-2: #09c2f1;
  --theme-color-3: #c7cad7;
  --theme-color-4: #25272f;

}

.theme-c1 { color:var(--theme-color-1); }
.theme-c2 { color:var(--theme-color-2); }
.theme-c3 { color:var(--theme-color-3); }
.theme-c4 { color:var(--theme-color-4); }
// .theme-c5 { color:var(--theme-color-5); }

.theme-b1 { background-color:var(--theme-color-1); }
.theme-b2 { background-color:var(--theme-color-2); }
.theme-b3 { background-color:var(--theme-color-3); }
.theme-b4 { background-color:var(--theme-color-4); }
// .theme-b5 { background-color:var(--theme-color-5); }

html,
body {
  font-family:'Poppins', sans-serif;
  @apply font-thin overflow-x-hidden text-base p-0 m-0 antialiased theme-b1 theme-c4;

  &.no-scroll {
    @apply overflow-hidden;
  }
}

a {
  @apply text-white;
}

* {
  box-sizing:border-box;
}

/* MENU */

.mainNav {
  z-index:100;
  @apply h-20 fixed top-0 left-0 right-0 px-16 animated-slow flex items-center text-base;
  @apply hidden md:flex;

  &.floating {
    // border-bottom: 2px solid var(--theme-color-4);
    @apply theme-b1 shadow-md;

    .Logo path {
      fill: var(--theme-color-4);
    }

    .navBar {
      button, a {
        @apply theme-c4;
      }

      button:hover, 
      a:hover,
      button.isOpen {
        @apply underline;
      }

      .dropdown-panel {
        @apply theme-b4;
    
        &-inner {
          @apply gap-0;
    
          a {
            @apply py-2 theme-c1;
    
            &:hover {
              @apply underline;
            }
          }
          
        }
      }


    }

    
  }

  &.navHidden {
    top:-100px !important;
    opacity:1;
  }

  .navBar {
    @apply flex h-full items-center font-normal;

    li,
    li > div {
      @apply flex h-full items-center;
    }

    button, 
    a {
      @apply h-full flex justify-center items-center px-6 animated theme-c4;

      svg {
        @apply w-4 h-4;
      }
    }

    button:hover, 
    a:hover,
    button.isOpen {
      @apply underline;
    }

    button.active,
    a.active {
      @apply underline;
    }

    .dropdown-panel {
      @apply absolute z-10 top-full left-auto right-0 theme-b4 animated min-w-full overflow-x-hidden shadow-xl;
  
      &-inner {
        @apply gap-0;
  
        a {
          @apply py-2 theme-c1;
  
          &:hover {
            @apply theme-b4;
          }
        }
        
      }
    }
  }
}

/* MOBILE MENU */

.mainNavMobile {
  z-index:100;
  @apply theme-b1 h-20 fixed top-0 left-0 right-0 px-6 animated-slow flex items-center text-base;
  @apply block lg:hidden;

  &.navHidden {
    top:-100px !important;
    opacity:1;
  }

  &.floating {
    @apply theme-b1 shadow;

    .Logo path {
      fill: var(--theme-color-4);
    }

    .navBar {
      button, a {
        @apply theme-c1;
      }

      button:hover, 
      a:hover,
      button.isOpen {
        @apply theme-b2 text-white;
      }

      .dropdown-panel {
        @apply theme-b2;
    
        &-inner {
          @apply gap-0;
    
          a {
            @apply py-2 text-white;
    
            &:hover {
              @apply theme-b4;
            }
          }
          
        }
      }


    }

    
  }

}

.burger {
  position:fixed;
  right:25px;
  z-index:99999;
  top:-75px;
  @apply animated-slow flex lg:hidden #{!important};

  &.btn_menu {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 28px;
      padding-left: 0;
      padding-right: 0;
      border: none;
      background-color: transparent;
      color: inherit;
      cursor: pointer;
      transition: .5s ease;

      &:focus {
          outline: none;
      }

      .btn_menu__bars {
          display: block;
          position: relative;
          width: 50px;
          height: 3px;
          @apply theme-b4;
          transition: .5s;

          &:before, &:after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              @apply theme-b4;
              transition: .5s;
          }

          &:before {
              transform: translate(0, -12px);
          }

          &:after {
              transform: translate(0, 12px);
          }
      }

      
  }

  &.menu_open {

    top:25px;

      .btn_menu__bars {
          background-color: transparent;
          &:before {
              transform: rotate(45deg);
              background-color: var(--theme-color-4);
          }
          &:after {
              transform: rotate(-45deg);
              background-color: var(--theme-color-4);
          }
      }
  }
}

.isTop {
  .burger {
    .btn_menu__bars {
        background-color:  var(--theme-color-4);
        &:before, &:after {
            background-color:  var(--theme-color-4);
        }
    }
    &.menu_open {
      .btn_menu__bars {
          background-color: transparent;
      }
    }
  }
}

.mobileMenuShow {
  .burger {
    top:25px;
  }
}

.mobileOffCanvas {
  height: 100%;
  position: fixed;
  z-index: 99998;
  top: 0;
  right: 0;
  transition: 0.5s;
  width: 100%;
  // -webkit-transform: translateX(0);
  transform: translateX(0);
  @apply flex justify-end block lg:hidden translate-x-full;

  .mobileMenu {
    @apply theme-b2 shadow-xl z-20 h-full w-full sm:w-96;

    .mobileMenuContainer {
      @apply mt-20 flex justify-end text-right;

      .navBar {
        @apply my-10 px-10;

        a, a.active {
          @apply font-normal text-xl theme-c1 mb-8;
        }

        button {
          @apply block font-normal text-xl theme-c4;
        }

        .dropdown-panel-inner {
          @apply flex flex-col mb-8;
          a {
            @apply mr-4 mb-0;
          }
        }

        button svg {
          @apply hidden;
        }
      }

    }
    
    .navBar {
      @apply my-10 px-10;
    }
  }
}

.mobileCanvasOverlay {
  left: 0;
  top: 0;
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 99997;
  @apply theme-b1 opacity-50;
  @apply hidden lg:hidden #{!important};
}

.mobileMenuOpened {

  .mobileCanvasOverlay {
    @apply block lg:hidden #{!important};
  }

  .mobileOffCanvas {
    @apply translate-x-0;
  }
}

/* GLOBAL */

.animated-slow {
  -webkit-transition:all 0.5s ease-out;
  -moz-transition:all 0.5s ease-out;
  -o-transition:all 0.5s ease-out;
  transition:all 0.5s ease-out;
}

.animated {
  -webkit-transition:all 0.2s ease-out;
  -moz-transition:all 0.2s ease-out;
  -o-transition:all 0.2s ease-out;
  transition:all 0.2s ease-out;
}

.Logo {
  @apply h-fit
}

.Logo path {
  fill: var(--theme-color-4);
}

.card {
  @apply shadow h-full animated font-normal text-sm border rounded-lg theme-c4 theme-b1;

  .image {
    @apply p-6 bg-white rounded-t-lg;
  }

  .caption {
    @apply p-6;
  }

  .card-btn {
    @apply px-6 py-2 uppercase text-xl flex animated justify-center items-center border theme-c4 font-medium;
    border-color: var(--theme-color-4);

    span {
      @apply mr-1;
      transition: transform .5s
    }

    // &:before{
    //   content: url('/mdi-arrow-right.svg');
    //   @apply w-5 h-5 mr-3 mb-1;
    // }

    &:hover {
      @apply theme-c1 theme-b4;

      span {
        @apply theme-c2;
        transform: rotate(180deg);
        // -ms-transform: rotate(90deg);
        // -webkit-transform: rotate(90deg);
      }
    }
  }

  &:hover {
    @apply shadow-2xl cursor-pointer;
  }
}

.swiper-button-prev,
.swiper-button-next {
  @apply theme-b4 rounded-full w-9 h-9 #{!important};
}

.swiper-button-next:after {
  @apply absolute -top-2;
  content: url('/mdi-chevron-right.svg') !important;
}

.swiper-button-prev:after {
  @apply absolute -top-2;
  content: url('/mdi-chevron-left.svg') !important;
}

.btn {
  @apply py-2 px-6 animated text-sm shadow hover:shadow-xl theme-c1 theme-b4;

  &:hover {
    @apply theme-b1 theme-c4;
  }
}

.btn-light {
  @apply font-normal border theme-c4;
  border-color:  var(--theme-color-4);
  background-color: transparent;
  &:hover {
    @apply theme-c4 theme-b1;
    background-color: white;
  }
}

.btn-big {
  @apply text-2xl font-normal;
}

.rowFooter {
  @apply bg-slate-800
}

.back-to-top {
  @apply rounded-md theme-b2 shadow-md opacity-70 right-6;

  span {
    border-color: var(--theme-color-1);
  }

  &:hover {
    @apply opacity-100;
  }
}

/* HOMEPAGE */
.home {

  #riga1 {
    // background:var(--theme-color-2);
    // background:linear-gradient(45deg, var(--theme-color-3) 50%, var(--theme-color-2) 50%);
    // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2500' height='1000' preserveAspectRatio='none' viewBox='0 0 2500 1000'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1350%26quot%3b)' fill='none'%3e%3cpath d='M -2373.844383023897%2c529 C -2123.84%2c499.8 -1623.84%2c307.4 -1123.844383023897%2c383 C -623.84%2c458.6 -373.84%2c893.2 126.15561697610292%2c907 C 626.16%2c920.8 901.39%2c495.4 1376.155616976103%2c452 C 1850.92%2c408.6 2275.23%2c642.4 2500%2c690' stroke='rgba(9%2c 194%2c 241%2c 0.53)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -1167.4086137478596%2c461 C -917.41%2c492.6 -417.41%2c632 82.59138625214047%2c619 C 582.59%2c606 832.59%2c353.6 1332.5913862521404%2c396 C 1832.59%2c438.4 2349.11%2c830.2 2582.5913862521406%2c831 C 2816.07%2c831.8 2516.52%2c486.2 2500%2c400' stroke='rgba(199%2c 202%2c 215%2c 0.56)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -1190.3882229273245%2c86 C -940.39%2c229 -440.39%2c718.6 59.61177707267562%2c801 C 559.61%2c883.4 809.61%2c543.2 1309.6117770726755%2c498 C 1809.61%2c452.8 2321.53%2c576.4 2559.6117770726755%2c575 C 2797.69%2c573.6 2511.92%2c507.8 2500%2c491' stroke='rgba(37%2c 39%2c 47%2c 0.6)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -1342.725807618539%2c643 C -1092.73%2c534.4 -592.73%2c71.6 -92.72580761853888%2c100 C 407.27%2c128.4 657.27%2c778.8 1157.274192381461%2c785 C 1657.27%2c791.2 2138.73%2c181.6 2407.274192381461%2c131 C 2675.82%2c80.4 2481.45%2c451.8 2500%2c532' stroke='rgba(199%2c 202%2c 215%2c 0.56)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1350'%3e%3crect width='2500' height='1000' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-image: url('/images/waves_ani.svg');
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    @apply py-16 md:pt-32 md:pb-28;

    .swiper-slide {
      @apply px-0;
      
      .captionContainer {
        @apply container mx-auto relative md:grid md:grid-cols-2 gap-12 items-center p-8 md:p-0 text-center md:text-left;
        
        .caption {
          -webkit-transition:all 0.5s ease-out;
          -moz-transition:all 0.5s ease-out;
          -o-transition:all 0.5s ease-out;
          transition:all 0.5s ease-out;

          @apply opacity-0 -translate-x-full mb-12 md:mb-0;

          p {
            @apply text-xl md:text-3xl p-0 m-0 font-normal theme-c4;
          }
          h4 {
            @apply relative text-4xl md:text-7xl p-0 m-0 font-semibold theme-c4 mt-12 md:mt-10;

            &::after {
              content:'';
              @apply w-24 h-2 mx-auto md:mx-0 block relative md:absolute md:-bottom-6 md:left-0 theme-b2 mt-3 md:mt-0;
            }
          }
        }

        .img-container {
          -webkit-transition:all 0.5s ease-out;
          -moz-transition:all 0.5s ease-out;
          -o-transition:all 0.5s ease-out;
          transition:all 0.5s ease-out;
          transition-delay: 300ms;

          @apply opacity-0 translate-x-full;
        }
      }

      &.swiper-slide-active {
        .caption {
          @apply opacity-100 translate-x-0;
        }
        .img-container {
          @apply opacity-100 translate-x-0;
        }
      }
    }

    .img-container {
      @apply animated;
    }
  
    .slide {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // height:calc(100vh - 80px);
        @apply flex items-center px-8 lg:px-16 xl:px-4;
    }


    
  }

  #riga2 {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2500' height='1200' preserveAspectRatio='none' viewBox='0 0 2500 1200'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1046%26quot%3b)' fill='none'%3e%3cpath d='M1085.15-238.97C855.65-194.05 745.65 390.44 394.16 391.55 42.68 392.66-100.97 31.12-296.82 19.55' stroke='rgba(9%2c 194%2c 241%2c 0.43)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2352.82-100.21C2105.83-69.96 1965.77 483.6 1549.49 487.49 1133.2 491.38 1147.82 337.49 746.15 337.49 344.49 337.49 147.12 486.85-57.18 487.49' stroke='rgba(9%2c 194%2c 241%2c 0.43)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1426.69-180.98C1167.82-28.57 1296.33 858.1 832.15 863.26 367.97 868.42-31.88 346.95-356.93 335.26' stroke='rgba(9%2c 194%2c 241%2c 0.43)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1214.99-97.1C969.35-34.9 959.96 565.62 513.28 634.28 66.6 702.94 13.98 1027.81-188.43 1042.28' stroke='rgba(9%2c 194%2c 241%2c 0.43)' stroke-width='2'%3e%3c/path%3e%3cpath d='M978.5-56.01C782.66 84.49 973.73 730.14 553.08 809.17 132.43 888.2-74.16 1081.75-297.76 1085.17' stroke='rgba(9%2c 194%2c 241%2c 0.43)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1046'%3e%3crect width='2500' height='1200' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-position: top left;
    background-size: auto 100%;
    background-repeat: no-repeat;

    @apply p-8 md:p-16 mt-0 theme-b4;
    h2 {
      @apply lg:p-16 text-center text-2xl md:text-4xl lg:text-5xl font-semibold text-white;
      &::after {
        content:'';
        @apply w-24 h-2 mx-auto block mt-6 theme-b2;
      }
    }
  }

  #riga3 {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2500' height='1200' preserveAspectRatio='none' viewBox='0 0 2500 1200'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1039%26quot%3b)' fill='none'%3e%3cpath d='M-289.74 863.91C-56.58 861.17 145.89 597.28 604.82 599.91 1063.75 602.54 1221.58 1238.59 1499.38 1275.04' stroke='rgba(79%2c 81%2c 89%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-30.05 751.21C176.1 738.58 308.78 346.82 695 355.21 1081.21 363.6 1143.13 1183.98 1420.04 1285.01' stroke='rgba(79%2c 81%2c 89%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-322.41 508.9C-76.48 516.71 83.95 872.26 583.81 892.9 1083.68 913.54 1235.82 1343.95 1490.04 1357.2' stroke='rgba(79%2c 81%2c 89%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-4.74 922.69C260.59 913.07 594.74 489.36 965.46 490.69 1336.18 492.02 1253.78 1117.5 1450.56 1207.49' stroke='rgba(79%2c 81%2c 89%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-89.8 355.32C219.15 358.89 579.21 663.59 1096.07 703.32 1612.93 743.05 1468.07 1347.78 1689 1420.88' stroke='rgba(79%2c 81%2c 89%2c 1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1039'%3e%3crect width='2500' height='1200' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-position: bottom left;
    background-size: 100% auto;
    background-repeat: no-repeat;

    @apply pb-32 px-8 lg:px-16 theme-b4;

    .grid {
      @apply gap-16 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4
    }

    .card {
      @apply flex flex-col border rounded-lg theme-c4;
      border-color: white;

      .caption {
        @apply flex-grow;
        h4 {
          @apply font-bold text-lg mb-3 theme-c4;
        }
      }

      &:hover {
        .caption h4 {
          @apply theme-c4;
        }
      }
    }
  }

  #riga4,
  #riga5 {
    @apply my-0 px-8 lg:px-16;

    .grid {
      @apply gap-10 md:gap-20;
    }

    .image {
      @apply  p-5;
      .imageContainer {
        min-height:300px;
        @apply h-full bg-center bg-contain bg-no-repeat;
      }
    }

    .text {
      @apply py-8 md:py-20;
    }

    h2 {
      @apply text-left text-2xl md:text-4xl relative font-normal mb-20;

      &::after {
        content:'';
        @apply w-24 h-2 absolute -bottom-6 left-0 theme-b2;
      }
    }

    p {
      @apply font-light;
    }
  }

  #riga4 {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2500' height='1200' preserveAspectRatio='none' viewBox='0 0 2500 1200'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1028%26quot%3b)' fill='none'%3e%3cpath d='M2979.92 555.38C2784.38 562.03 2661.79 886.22 2262.18 867.38 1862.58 848.54 1830.73-28.64 1544.45-151.72' stroke='rgba(9%2c 194%2c 241%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2938.98 710.44C2697.91 699.62 2455.53 325.36 2069.89 290.44 1684.26 255.52 1782.42-94.21 1635.35-125.92' stroke='rgba(9%2c 194%2c 241%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2887.64 741.8C2681.83 725.03 2605 351.84 2183.65 309.8 1762.3 267.76 1695.38-173.92 1479.66-199.71' stroke='rgba(9%2c 194%2c 241%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2903.97 384.44C2608.41 400.99 2272.02 944.05 1856.42 936.44 1440.81 928.83 1576.21 22.08 1332.64-159.14' stroke='rgba(9%2c 194%2c 241%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M2735.95 148.47C2505.72 153.17 2203.21 462.54 1869.29 460.47 1535.37 458.4 1609.22-83.15 1435.96-158.3' stroke='rgba(9%2c 194%2c 241%2c 1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1028'%3e%3crect width='2500' height='1200' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-position: top right;
    background-size: auto 100%;
    background-repeat: no-repeat;
    @apply md:pt-28;
  }

  #riga6, #riga7 {
    @apply bg-gray-100;
  }

  #riga6 {
    @apply pt-16 mt-8 px-8;

    h2 {
      @apply text-center text-2xl md:text-4xl lg:text-5xl font-semibold theme-c4;

      &::after {
        content:'';
        @apply w-24 h-2 mx-auto block mt-6 theme-b2;
      }
    }
  }

  #riga7 {
    // @apply theme-b3 theme-c1;
    @apply px-8 lg:px-16;

    img {
      max-width: 150px;
      max-height: 150px;
      border: 8px solid var(--theme-color-2) !important;
      @apply rounded-full bg-white;
    }

    h4:first-of-type {
      @apply mt-5 theme-c4;
    }

    p {
      @apply font-normal text-xs;
    }

    .swiper-slide {
      @apply px-8 xl:px-32;
    }
  }

  #riga7,
  #riga8 {
    @apply py-8;
  }

  #riga8 {
    // background-image: url('/images/drop2.svg');
    background-position: top right;
    background-size:auto 100%;

    @apply bg-no-repeat px-8;

    h2 {
      @apply font-semibold text-2xl md:text-4xl lg:text-5xl;
    }

    .text {
      @apply text-center text-5xl font-thin pt-8 pb-8;
    }
  }
  
  #riga9 {
    // background-image: url('/images/drop2.svg');
    background-position: top right;
    background-size:auto 100%;
    @apply pb-12 bg-no-repeat;

    .text > div {
      @apply mb-5;
    }

    h3 {
      @apply text-center text-4xl md:text-6xl font-semibold theme-c2;
    }

    p {
      @apply text-center;
    }
  }

  #riga10, #contattaci {
    @apply p-8 md:py-36 bg-no-repeat theme-b3;

    .text {
      h2 {
        @apply text-center text-2xl md:text-4xl lg:text-5xl font-normal pb-6 theme-c4;
      }
      p {
        @apply text-center font-light;
      }
    }
  }

  #riga11 {
    @apply text-white overflow-hidden pb-16 text-sm;
  
    h4 {
      @apply text-2xl text-white font-normal;
    }
  
    .logo {
      svg {
        @apply h-16 w-auto my-8;
      }
  
      path {
        @apply fill-white;
      }
    }
  }

}

#diconodinoi {
  img {
    @apply rounded-full;
  }
  p {
    @apply text-left text-sm italic;
  }
}

/* FOOTER */

#footer {
  @apply text-center md:text-left text-white overflow-hidden pb-16 text-sm theme-b4 px-8 lg:px-16 xl:px-4;

  h4 {
    @apply text-2xl text-white font-normal;
  }

  .logo {
    svg {
      @apply h-16 w-auto my-8 mx-auto md:mx-0;
    }

    path {
      // @apply fill-white;
      fill:var(--theme-color-2);
    }
  }
}

#postfooter {
  @apply py-8 font-normal;
}

/* CASE HISTORY */

main.case-history {
  @apply py-32;
  .text {
    h1 {
      @apply text-center text-2xl md:text-4xl lg:text-5xl font-semibold theme-c4;

      &::after {
        content:'';
        @apply w-24 h-2 mx-auto block mt-2 theme-b2;
      }
    }
  }

  .card {
    @apply h-auto flex flex-col lg:flex-row justify-between rounded-none border-0 shadow-none hover:shadow-none hover:cursor-auto my-16;
    
    .image {
      @apply w-full lg:w-1/2;
    }

    .caption {
      @apply w-full lg:w-1/2 text-left;

      h3 {
        @apply text-3xl font-bold mb-8;
      }
    }

  }

  #case_history_cards {
    & > div:nth-child(odd) {
      .card {
        .image {
          @apply lg:order-1;
        }
        .caption {
          @apply lg:order-2;
        }
      }
    }
    
    & > div:nth-child(even) {
      .card {
        .image {
          @apply lg:order-2;
        }
        .caption {
          @apply lg:order-1;
        }
      }
    }
  }

}

/* PRIVACY */

main.page {

  @apply theme-c1;

  #riga1 {
    @apply pt-36 pb-24 theme-b1;

    h2 {
      @apply relative text-7xl p-0 m-0 font-normal theme-c4;

      &::after {
        content:'';
        @apply w-24 h-2 theme-b2 absolute -bottom-6 left-0;
      }
    }
  }

  #riga2 {
    @apply py-16 theme-b1 theme-c4;

    h2 {
      @apply text-3xl font-normal theme-c4 mt-12 mb-4;
    }
    h3 {
      @apply text-xl font-normal theme-c4 my-4;
    }
  }

  p {
    @apply my-3;
  }

  ul {
    @apply list-disc pl-6;
  }

  a {
    @apply theme-c2;
  }

}

/* CONTACT FORM */

.modal-cms {
  @apply justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none;

  &-container {
    min-width:500px;
    @apply relative w-auto my-6 mx-auto max-w-4xl;

    &-inner {
      @apply border-0 shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white;
    }
  }

  &-header {
    @apply flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t;

    h3 {
      @apply text-3xl font-semibold theme-c4;
    }

    .closeBtn {
      @apply p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none;
    }
  }
  .modal-form-row {
    border-color:#d3d3d3;
    @apply border-b py-2 mb-8;
  }
  .required {
    @apply text-red-600;
  }

  .sendBtn {
    @apply animated theme-b2 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-2xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150;
    &:hover {
      @apply theme-b4 text-white;
    }
  }
}

/* PROGRESS BAR */

#nprogress {
  .bar {
    .peg {
      @apply hidden;
    }
  }
}

@media (max-width: 500px) { 
  .modal-cms-container {
    min-width:100%;
  }
}